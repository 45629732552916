<template>
  <section v-if="!appNotInstalled">
    <div class="float-right mr-2 mb-2 d-flex mt-n3">
      <div v-if="canEdit" class="edit-icon-settings d-flex align-items-center pointer">
        <feather-icon
          v-if="!isEditing"
          v-b-tooltip.hover.bottom
          icon="Edit2Icon"
          :title="$t('form-create-item.edit')"
          size="20"
          class="text-primary zindex-1"
          :class="
            !isStaff && !canEdit ? 'd-block mr-n50' : 'd-none d-lg-block'
          "
          @click="openEdit"
        />
        <feather-icon
          v-if="isEditing"
          icon="XIcon"
          size="20"
          class="text-primary zindex-1 mr-1"
          :class="
            !isStaff && !canEdit ? 'd-block mr-n50' : 'd-none d-lg-block'
          "
          @click="closeEdit"
        />
        <feather-icon
          v-if="isEditing"
          v-b-tooltip.hover.bottom
          icon="SaveIcon"
          :title="$t('header.action.save-layout')"
          size="20"
          class="text-primary zindex-1"
          :class="
            !isStaff && !canEdit ? 'd-block mr-n50' : 'd-none d-lg-block'
          "
          @click="handleEditOrder"
        />
      </div>
      
      <!-- Toggle filters button -->
      <div
        v-if="classifiersTree.length > 0"
        id="toggle-filter-button"
        class="text-primary ml-1 pointer position-relative"
        @click="canFilter"
      >
        <b-button variant="link" class="p-0 toggle-filters-button">
          <b-icon-sliders />
        </b-button>
      </div>

      <!-- Toggle collaborators button -->
      <div
        v-if="collaborators.length > 0"
        id="toggle-collaborators-button"
        class="text-primary ml-1 pointer position-relative"
        @click="canViewCollaborators"
      >
        <b-button variant="link" class="p-0 toggle-filters-button" >
          <b-icon-person-check v-if="!showCollaborators"/>
          <b-icon-person-x v-if="showCollaborators" />
        </b-button>
      </div>
    </div>

    <b-row class="media m-0">
      <!-- FILTERS COLUMN -->
      <b-col v-if="showFilter" cols="12" lg="2" class="media__filters pl-0">
        <b-card class="addon">
          <div class="mx-50 d-flex justify-content-between align-items-center">
            <h3>
              {{ $t("media.filters.action.filter") }}
            </h3>
            <b-icon-filter
              v-if="classifiersTree.length > 0"
              variant="primary"
              class="d-lg-none"
              @click="toggleFilters"
            />
          </div>
          <div
            v-if="classifiersTree.length > 0"
            class="flex-column justify-content-center filters-container mb-2 px-50 pt-1"
            :class="areFiltersVisible ? 'd-flex' : 'd-none d-lg-flex'"
          >
            <div class="d-flex flex-column filters-container mb-2 px-50 pt-1">

              <!-- Filters -->
              <b-form-group
                v-for="classifierType in classifiersTree"
                :key="classifierType.key"
                :label="translateTranslationTable(currentLocale, classifierType.name)"
                :label-for="`classifier-filter-${classifierType.key}`"
                class="w-100"
              >
                <v-select
                  v-model="selectedClassifiersForFilter[classifierType.key]"
                  :name="`classifier-filter-${classifierType.key}`"
                  clearable
                  :searchable=false
                  label="name"
                  placeholder="Select..."
                  :reduce="(a) => a"
                  return-object
                  :options="filterOptions(classifierType)"
                  @input="setSelectedFinalFilter"
                />
              </b-form-group>
              
              <!-- Filter logic -->
              <b-form-group
                :label="$t('filters.logic')"
                label-for="filterLogicLabel"
                class="w-100"
              >
                <v-select
                  name="filterLogicLabel"
                  v-model="filterLogic"
                  :searchable=false
                  :clearable="false"
                  label="name"
                  :reduce="(a) => a"
                  return-object
                  placeholder="Select..."
                  :options="orAndOptions"
                />
              </b-form-group>

              <!-- Filter type and value -->
              <!--b-form-group
                label="filter Type"
                label-for="classifier-filter"
                class="mr-1 w-100"
              >
                <v-select
                  v-model="selectedClassifiersForFilter"
                  name="classifier-filter"
                  clearable
                  label="name"
                  :reduce="(a) => a"
                  :options="finalOptions"
                  @input="setSelectedFilter"
                />
              </b-form-group>
              <b-form-group
                v-if="nextFilter != null"
                label="Value"
                label-for="custom-filter"
                class="mr-1 w-100"
              >
                <v-select
                  v-if="nextFilter[0] != null"
                  v-model="selectedCustomFieldsForFilter"
                  name="custom-filter"
                  clearable
                  label="name"
                  :reduce="(nextFilter) => nextFilter"
                  :options="nextFilter"
                  @input="setSelectedFinalFilter"
                />

                <b-form-input
                  v-if="nextFilter.type === 2"
                  v-model="customFieldToSend[nextFilter.key]"
                  placeholder="Start typing..."
                  class="w-100"
                  @keyup="handleChanger"
                  @input="
                    setSelectedFinalFilter({
                      text: $event,
                      type: 2,
                      key: nextFilter.key,
                    })
                  "
                />
              </b-form-group-->
              <!-- End Custom -->
              <b-button
              variant="primary"
              type="submit"
              @click.prevent="handleChanger(true)"
              >
                SEARCH
              </b-button>
            </div>
          </div>
          <!-- No filters available -->
          <b-row
            v-else
            class="horizontal-placeholder d-none d-lg-block h-100 media-placeholder"
          >
            <b-col cols="12">
              <img :src="placeholderFilter" />
            </b-col>
            <b-col cols="12">
              <p class="text-primary">
                {{ $t("available.message", { itemName: $t("media.filters.action.filter") }) }}
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>

      <!-- MEDIA TABLE -->
      <b-col
        cols="12"
        :lg="showFilter ? (showCollaborators ? 8 : 10) : showCollaborators ? 10 : 12"
      >
        <b-card class="container-fluid px-0 addon">
          <!-- Widget header (title and buttons) -->
          <div class="d-flex justify-content-between mx-2">
            <h3>
              {{ translateTranslationTable(currentLocale, title) }}
            </h3>
            <widget-actions
              class="d-flex justify-content-end float-none mb-25"
              :item-type="`${mediaType}s`"
              :show-add="canAdd"
              :is-media="true"
              :show-list-type="true"
              :show-list="true"
              :show-filter="false"
              :is-search-needed="true"
              :show-maximize="false"
              :is-list-type-card="isListTypeCard"
              @updateSearch="searchItems"
              @openMediaModal="isAddingMedia = true"
              @toggleListType="toggleListType"
            />
          </div>

          <!-- Completion bar -->
          <media-progress-bar
            v-if="totalElementsWithCompletion && !isListTypeCard"
            :elements-completion="elementsCompletion"
          />

          <!-- Widget content -->
          <media-vertical-list
            v-if="itemsData && itemsData.length > 0"
            :items-data="itemsData"
            :media-type="mediaType"
            :per-page="6"
            :isEditing="isEditing"
            :is-list-type-card="isListTypeCard"
            :isStaff="canPostMedia"
            :total="total"
            @moveItems="moveItems"
          />

          <!-- Container without data: Placeholder -->
          <b-row
            v-if="itemsData && itemsData.length === 0"
            class="horizontal-placeholder h-100 media-placeholder"
          >
            <b-col cols="12">
              <img :src="placeholderMedia" />
            </b-col>
            <b-col cols="12">
              <p class="text-primary">
                {{ $t("available.message", { itemName: translateTranslationTable(currentLocale, title) }) }}
              </p>
            </b-col>
          </b-row>

          <!-- Load more data -->
          <div
            v-if="canLoadMoreData"
            class="w-100 float-left"
          >
            <b-button
              v-if="!isLoadingNextPage"
              class="center-x my-3"
              variant="outline-primary"
              @click="handleLoadOfNewItems"
            >
              {{ $t("action.load-more") }}
            </b-button>
            <div v-else class="w-100">
              <b-spinner
                class="center-x my-3"
                small
                variant="primary"
                type="grow"
                label="Loading..."
              />
            </div>
          </div>
        </b-card>

        <!-- Upload File modal -->
        <media-upload-modal 
          v-model="isAddingMedia" 
          :media-type="mediaType" 
          @media-created="mediaCreated"
        />
      </b-col>

      <!-- COLLABORATORS -->
      <b-col v-if="showCollaborators" cols="12" lg="2" class="pr-0">
        <b-card class="addon px-50">
          <h3>{{ $t("media.collaborators.title") }}</h3>
          <b-row v-if="collaborators.length > 0">
            <b-col
              v-for="collaborator in collaborators"
              :key="collaborator.key"
              cols="4"
              class="mt-1"
            >
              <b-link :to="getMemberLocation(collaborator)">
                <div class="d-flex justify-content-center">
                  <user-avatar
                    v-if="collaborator"
                    :user="collaborator"
                    size="46"
                    class="mr-50 mb-25"
                  />
                </div>
                <div class="d-flex justify-content-center">
                  <p
                    v-if="collaborator"
                    class="small m-0 text-center text-secondary text-ellipsis-lines-2"
                  >
                    {{ collaborator.name }} {{ collaborator.surname }}
                  </p>
                </div>
              </b-link>
            </b-col>
          </b-row>
          <!-- Container without data: Placeholder -->
          <b-row v-else class="horizontal-placeholder h-100 media-placeholder">
            <b-col cols="12">
              <img :src="placeholderCollaborator" />
            </b-col>
            <b-col cols="12">
              <p class="text-primary">
                {{
                  $t("available.message", {
                    itemName: translateTranslationTable(currentLocale, title),
                  })
                }}
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </section>
  <section v-else>
    <uninstalled-addon
      :addonKey="appKey" 
    />
  </section>
</template>

<script>
import MediaUploadModal from "@/views/apps/media/components/modal/MediaUploadModal.vue";
import MediaProgressBar from "@/views/apps/media/components/MediaProgressBar.vue";
import MediaVerticalList from "@/views/apps/media/components/MediaVerticalList.vue";
import MediaConfigurationMixin from "@/views/apps/media/mixins/MediaConfigurationMixin";
import WidgetActions from "@/@core/components/widget-actions/WidgetActions.vue";
import FilterPlaceholder from "@/assets/images/placeholders/light/filters.svg";
import MembersPlaceholder from "@/assets/images/placeholders/light/members.svg";
import { translateTranslationTable } from "@/@core/libs/i18n/utils";
import vSelect from "vue-select";
import UserAvatar from "@core/components/user/UserAvatar.vue";
import { PlaceholdersImage } from "@/views/apps/media/constants/MediaType";
import { checkPermissions } from "@/@core/utils/roles-utils";
import { ENABLED_APPS_GETTERS } from "@/store/enabled-apps/enabled-apps-store-constants";
import UninstalledAddon from '@/@core/components/UninstalledApps/UninstalledAddon.vue'

export default {
  name: "MediaList",
  components: {
    MediaUploadModal,
    MediaVerticalList,
    WidgetActions,
    vSelect,
    UserAvatar,
    UninstalledAddon,
    MediaProgressBar,
  },
  mixins: [MediaConfigurationMixin],
  props: {
    small: Boolean,
  },
  data() {
    return {
      isAddingMedia: false,
      isListTypeCard: true,
      lastLoadedPage: 1,
      isLoading: false,
      isLoadingNextPage: false,
      // search data
      isFiltering: false,
      searchInput: "",
      results: [],

      // Filters data
      filterLogic: { name: this.$t("AND"), value: "AND" },
      areFiltersVisible: true,
      nextFilter: null,
      selectedClassifiersForFilter: [],
      finalSelectClassifier: [],
      selectedFilteringClassifiers: [],
      selectedCustomFieldsForFilter: null,
      customFieldToSend: {},
      showFilter: false,
      showCollaborators: false,
      //order
      isEditing: false,
      actualItems: [],
      isSending: false,
      firstItems: [],
      appNotInstalled: false,
      hasCompletableElements:false,
      elementsCompletion: null,
    };
  },
  updated(){
    this.updateBreadcrumbs();
  },
  computed: {
    appKey() {
      // return this.mediaType + 's';
      return this.$route.name;
    },
    itemsData() {
      if (this.items) {
        // console.log('this.items', this.items);
        // Si estamos editando el orden de los elementos
        if(this.isEditing) {
          return this.items.unpaginated;
        }
        // Si estamos realizando una búsqueda
        if(this.searchInput){
          return this.results;
        }
        // Si estamos realizando una búsqueda
        if(this.isFiltering) {
          this.isFiltering = false;
          return this.items.unpaginated;
        }
        if(!this.items.paginated){
          return this.items.unpaginated;
        }
        // Si estamos cargando alguna página nueva
        // console.log('Retrieving items from page');
        // console.log('this.items?.paginated', this.items?.paginated);
        let itemsData = [];
        const pages = Object.values(this.items?.paginated);
        // console.log('pages', pages);

        for(const page of pages){
          // console.log('new page data:', page)
          for(const item of page){
            itemsData.push(item);
          }
          // si lo que nos viene del endpoint en meta es menor que el contenido de la primera página, quiere decir que se nos había quedado restos de lo anterior en páginas siguientes
          //TODO: mejorar...
          // console.log('this.items.meta.total', this.items.meta.total);
          // console.log('page.length', page.length);
          if(this.items.meta.total <= page.length){
            return itemsData;
          }
        }
        // console.log('itemsData', itemsData);

        return itemsData;
      }
      return [];
    },
    items() {
      const mediaItems = this.$store.getters.media[this.mediaType];
      // console.log('mediaItems', mediaItems);
      
      if (mediaItems) {
        if(mediaItems.length > 1){
          return mediaItems;
        }
        // this.fetchData(true);
        return this.$store.getters.media[this.mediaType];
      }
      return [];
    },
    total(){
      if(this.items){
        return this.items?.meta?.total;
      }
      return 0;
    },
    widget() {
      const app = Object.values(
        this.$store.getters[ENABLED_APPS_GETTERS.enabledApps]
      ).filter((item) => {
        if (item.nameSingular === this.mediaType) {
          return item.name;
        }
      });
      return app[0];
    },
    title() {
      return this.widget?.customizationName ? this.widget?.customizationName : this.widget?.name
    },
    collaborators() {
      const collaborators = [];
      // console.log('this.itemsData', this.itemsData);
      if(this.itemsData){
        for (const item of this.itemsData) {
          if (item.createdBy && item.createdBy.name) {
            if (collaborators.length === 0) {
              collaborators.push(item.createdBy);
            } else {
              const validName = collaborators.some(
                (collaborator) => collaborator.name === item.createdBy.name
              );
              const validSurname = collaborators.some(
                (collaborator) => collaborator.surname === item.createdBy.surname
              );
              if (!validName && !validSurname) {
                collaborators.push(item.createdBy);
              }
            }
          }
        }
      }
      return collaborators;
    },
    canPostMedia() {
      return this.$store.getters.currentCollective.isStaff;
    },
    currentLocale() {
      return this.$store.state.locale.currentLocale;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    collective() {
      return this.$store.state.collective.collective;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    canAdd() {
      return (
        this.isStaff ||
        checkPermissions(
          "create",
          `${this.mediaType}`,
          this.loggedMemberRoles,
          this.collective
        )
      );
    },
    canEdit() {
      return checkPermissions(
        'update',
        `${this.mediaType}`,
        this.loggedMemberRoles,
        this.collective,
      );
    },

    // Filtros computed
    classifiersTree() {
      if (this.$store.getters.classifiersTree[this.mediaType]) {
        return this.$store.getters.classifiersTree[this.mediaType].unpaginated;
      }
      return [];
    },
    classifiers() {
      if (this.$store.getters.classifiers[this.mediaType]) {
        return this.$store.getters.classifiers[this.mediaType].unpaginated;
      }
      return [];
    },
    placeholderFilter() {
      return FilterPlaceholder;
    },
    placeholderMedia() {
      return PlaceholdersImage(this.mediaType);
    },
    placeholderCollaborator() {
      return MembersPlaceholder;
    },
    sortedCommunityClassifiers() {
      const newObject = {};
      for (const item of this.classifiers) {
        if (
          !newObject[
            translateTranslationTable(this.currentLocale, item.typeName)
          ]
        ) {
          newObject[
            translateTranslationTable(this.currentLocale, item.typeName)
          ] = [item];
        } else {
          newObject[
            translateTranslationTable(this.currentLocale, item.typeName)
          ].push(item);
        }
      }
      const keys = Object.keys(newObject);
      const finalKeys = [];
      for (const row of keys) {
        finalKeys.push({ key: row, name: row, type: 1 });
      }
      // console.log('newObject', newObject);
      return [newObject, finalKeys];
    },
    finalOptions() {
      return [].concat(
        this.sortedCommunityClassifiers[1],
        this.customFieldsOptions
      );
    }, // Final filtros computed
    totalElementsWithCompletion() {
      //console.log('items', this.items);
      let completableItemsCount = 0;
      let completedItemsCount = 0;
      let inprogressItemsCount = 0;
      let uncompletedItemsCount = 0;
      this.items?.unpaginated?.map(item => {
        if(item.isCompletable){
          this.hasCompletableElements = true;
          completableItemsCount += 1;
          if(item.completedByMember){
            completedItemsCount += 1;
          }else{
            uncompletedItemsCount += 1;
          }
        }
      });
      this.elementsCompletion = {
        'total': completableItemsCount,
        'completed': completedItemsCount,
        'inprogress': inprogressItemsCount,
        'uncompleted': uncompletedItemsCount,
      };
      //console.log('this.elementsCompletion', this.elementsCompletion);
      return this.hasCompletableElements;
    },
    orAndOptions() {
      //return ["OR", "AND"];
      return [
        { name: this.$t("AND"), value: "AND" },
        { name: this.$t("OR"), value: "OR" }
      ];
    },
    canLoadMoreData() {
      // console.log('canLoadMoreData...')
      // console.log('this.itemsData?.length', this.itemsData?.length);
      // console.log('this.total', this.total);
      return this.itemsData && this.itemsData?.length < this.total;
    }
  },
  async created() {
    this.isLoading = true;
    await this.fetchData(true);
    //await this.fetchClassifiers();
    await this.fetchClassifiersTree(true);
    this.canFilter();
    this.mediaDisplay();
    //this.totalElementsWithCompletion();
    this.isLoading = false;
  },
  methods: {
    filterOptions(classifierType) {
      let translatedClassifiers = [];
      for(let classifier of classifierType.classifiers){
        //console.log('classifier', classifier);
        translatedClassifiers.push({
          // text: this.translateTranslationTable(this.currentLocale, classifier.name),
          // value: classifier.key,
          name: this.translateTranslationTable(this.currentLocale, classifier.name),
          typeKey: classifierType.key,
          key: classifier.key,
          type: 1,
        });
      }
      return translatedClassifiers;
    },
    async mediaCreated() {
      await this.fetchData(true);
    },
    async mediaDisplay() {
      let elementIndex = '';
      switch (this.mediaType) {
        case "video":
          elementIndex = 7;
          break;
        case "document":
          elementIndex = 25;
          break;
        case "photo":
          elementIndex = 26;
          break;
        case "audio":
          elementIndex = 27;
          break;
        case "presentation":
          elementIndex = 46;
          break;
        case "spreadsheet":
          elementIndex = 73;
          break;
      }
      let res = this.$store.getters.apps;
      let data = res.apps;
      if (data[elementIndex]) {
        if (data[elementIndex]?.customization != null) {
          if (data[elementIndex].customization.displayOptions != null || data[elementIndex].customization.mediaDisplay != null) {
            this.isListTypeCard = data[elementIndex].customization.displayOptions || data[elementIndex].customization.mediaDisplay || false;
            this.showCollaborators = data[elementIndex].customization.collaboratorDisplay;
          }
        }
      } else {
        this.appNotInstalled = true;
      }
    },
    toggleListType() {
      this.isListTypeCard = !this.isListTypeCard;
      return this.isListTypeCard;
    },
    getMemberLocation(user) {
      if (!user.username) {
        return null;
      }
      return {
        name: "member-details",
        params: {
          username: user.username,
          communityId: this.$route.params.communityId,
        },
      };
    },

    updateBreadcrumbs() {
      const breadcrumbs = [
        {
          text: `${this.mediaType}s.breadcrumb-text.${this.mediaType}s-list`,
          active: true,
        },
      ];
      this.$store.commit("app/SET_BREADCRUMBS", breadcrumbs);
    },

    async handleLoadOfNewItems() {
      const { total } = this.items.meta;
      if (!this.isLoading && this.itemsData.length < total) {
        this.isLoadingNextPage = true;
        this.lastLoadedPage += 1;
        await this.fetchData(true);
        this.isLoadingNextPage = false;
      }
    },
    canFilter() {
      if (this.classifiersTree.length > 0) {
        this.showFilter = !this.showFilter;
      } else {
        this.showFilter = false;
      }
    },
    canViewCollaborators() {
      if (this.collaborators.length > 0) {
        this.showCollaborators = !this.showCollaborators;
      } else {
        this.showCollaborators = false;
      }
    },

    async fetchData(force = false) {
      // console.log('Fetching data media items in MediaList.vue');
      // console.log('this.filterLogic', this.filterLogic);
      const getItemsData = {
        itemType: "media",
        storedKey: this.mediaType,
        page: this.lastLoadedPage,
        forceAPICall: force,
        perPage: 20,
        requestConfig: {
          morphType: this.mediaType,
          orderByDate: -1,
          //  classifiers: this.finalSelectClassifier,
          classifiers: this.selectedFilteringClassifiers,
          customFields:
            this.customFieldToSend != null &&
            Object.keys(this.customFieldToSend).length > 0
              ? this.customFieldToSend
              : null,
          filterLogic: this.filterLogic.value
        },
      };
      // console.log('getItemsData', getItemsData);
      const response = await this.$store.dispatch("getItems", getItemsData);
      // console.log('getItems media response', response)
    },

    // Filtros methods
    toggleFilters() {
      this.areFiltersVisible = !this.areFiltersVisible;
      if (!this.areFiltersVisible) {
        this.customFieldToSend = {};
        this.finalSelectClassifier = [];
        this.selectedFilteringClassifiers = [];
        (this.selectedCustomFieldsForFilter = null), (this.nextFilter = null);
        (this.total = 0),
          (this.filtersIndex = 0),
          (this.filters = []),
          (this.parentClassifiers = []),
          (this.selectedClassifiersForFilter = []);
        this.customFieldsInputs = [];
        this.index = 0;
        this.fetchData(true);
      }
    },
    handleChanger(isFiltering = false) {
      if(isFiltering){
        this.isFiltering = true;
        this.lastLoadedPage = 1;
      }
      this.fetchData(true);
    },
    async fetchClassifiers() {
      // console.log('fetching classifiers');
      await this.$store.dispatch("getItems", {
        itemType: "classifiers",
        storedKey: this.mediaType,
        requestConfig: {
          morphType: this.mediaType,
          count: 1000,
        },
      });
    },
    async fetchClassifiersTree(force = false) {
      // console.log('fetching classifiersTree');
      await this.$store.dispatch("getItems", {
        itemType: "classifiersTree",
        storedKey: this.mediaType,
        forceAPICall: force,
        requestConfig: {
          morphType: this.mediaType,
          count: 1000,
        },
      });
    },
    // async setSelectedFilter(selected) {
    //   console.log('selected', selected);
    //   this.CustomFieldToSend = null;
    //   this.finalSelectClassifier = null;
    //   this.selectedCustomFieldsForFilter = null;
    //   this.nextFilter = null;
    //   if (selected.length === 0) {
    //     this.finalSelectClassifier = null;
    //   } else {
    //     let translated = [];
    //     if (selected.type === 1) {
    //       for (const row of this.sortedCommunityClassifiers[0][selected.key]) {
    //         translated.push({
    //           name: translateTranslationTable(this.currentLocale, row.name),
    //           key: row.key,
    //           type: 1,
    //         });
    //       }
    //     } else if (selected.type === 2) {
    //       this.customFieldToSend[selected.key] = "";
    //       translated = {
    //         type: 2,
    //         key: selected.key,
    //       };
    //     }
    //     this.nextFilter = translated;
    //   }
    // },
    async setSelectedFilter(selected) {
      // console.log('setSelectedFilter selected', selected);
      this.CustomFieldToSend = null;
      this.finalSelectClassifier = null;
      this.selectedFilteringClassifiers = null;
      this.selectedCustomFieldsForFilter = null;
      this.nextFilter = null;
      if (selected.length === 0) {
        this.finalSelectClassifier = null;
        this.selectedFilteringClassifiers = null;
      } else {
        let translated = [];
        if (selected.type === 1) {
          for (const row of this.sortedCommunityClassifiers[0][selected.key]) {
            translated.push({
              name: translateTranslationTable(this.currentLocale, row.name),
              key: row.key,
              type: 1,
            });
          }
        } else if (selected.type === 2) {
          this.customFieldToSend[selected.key] = "";
          translated = {
            type: 2,
            key: selected.key,
          };
        }
        this.nextFilter = translated;
      }
    },
    async setSelectedFinalFilter(selected) {
      // console.log('setSelectedFinalFilter selected', selected);
      // console.log('this.selectedClassifiersForFilter', this.selectedClassifiersForFilter);
      this.selectedFilteringClassifiers = [];
      for(let selectedClassifier of Object.values(this.selectedClassifiersForFilter)){
        // console.log('selectedClassifier', selectedClassifier);
        if(selectedClassifier?.key){
          this.selectedFilteringClassifiers.push(selectedClassifier.key);
        }
      }
      // console.log('this.selectedFilteringClassifiers', this.selectedFilteringClassifiers);
      //Lo vamos a hacer con un botón de buscar, así ahorramos queries innecesarias
      //this.handleChanger();
    },
    // Final filtros methods
    // Search methods
    searchItems(value) {
      this.searchInput = value;
      const searchTerm = value.toLowerCase().trim();
      if (!searchTerm) {
        return;
      }
      this.results = this.items.unpaginated.filter((item) =>
        typeof item.name === "string"
          ? item.name.toLowerCase().includes(searchTerm)
          : translateTranslationTable(this.currentLocale, item.name)
              .toLowerCase()
              .includes(searchTerm)
      );
    },

    //Order
    moveItems(items){
      this.actualItems=items;
    },
    async handleEditOrder() {
      this.isSending = true;
      for (const [index, item] of this.actualItems.entries()) {
        item.order = index + 1;
      }
      await this.$store.dispatch('editItem', {
        noSet: true,
        item: {
          itemType: '/media/order',
          requestConfig: {
            media: this.actualItems,
          },
        },
      });
      this.firstItems = [];
      this.firstItems = [...this.actualItems];
      this.isEditing = false;
      this.isSending = false;
    },
    openEdit() {
      this.isListTypeCard = true;
      this.firstItems = [];
      this.firstItems = [...this.itemsData];
      this.isEditing = true;
    },
    closeEdit() {
      this.$store.commit('SET_ITEMS', {
        type: this.mediaType === 'file' ? 'storage' : 'media',
        storedKey: this.mediaType === 'file' ? null : this.mediaType,
        forceAPICall: true,
        page: 1,
        data: {
          data: this.firstItems,
          meta: {
            current_page: 1,
            total: this.firstItems.length,
          },
        },
      });
      this.isEditing = false;
    },
    translateTranslationTable,
  },
};
</script>

<style lang="scss" scoped>
.card {
  background-color: white;
}
.media {
  width: 100%;
}
.media-placeholder {
  img {
    width: 100%;
    max-width: 160px;
  }
}
@import "~@core/scss/base/bootstrap-include";
.add-media-button {
  @include media-breakpoint-up(md) {
    position: relative;
    top: -55px;
  }
}
@include media-breakpoint-up(lg) {
  .addon {
    min-height: 44vh;
  }
}
</style>
