<template>
    <FileUploadModal
      :visible="visible"
      :value="media"
      :title="getTitle"
      :type="type"
      :link-enabled="true"
      :edit-media="editMedia"
      :is-saving="isSaving"
      @save="postMedia"
      @change="$emit('change', $event)"
    />
</template>

<script>
import MediaConfigurationMixin from '@/views/apps/media/mixins/MediaConfigurationMixin';
import MediaType from '@/views/apps/media/constants/MediaType';
import ToastNotificationsMixin from '@core/mixins/toast-notifications/ToastNotificationsMixin';

export default {
  name: 'MediaUploadModal',
  components: {
    FileUploadModal: () => import('@core/components/modal/FileUploadModal.vue' /* webpackChunkName: "FileUploadModal" */),
  },
  mixins: [MediaConfigurationMixin, ToastNotificationsMixin],
  model: {
    prop: 'visible',
    event: 'change',
  },
  props: {
    visible: Boolean,
    media: {
      type: Object, // for edit
      default: null,
    },
    datos: {
      type: Function,
      default: null,
    },
    editMedia: {
      type: Boolean,
      default: false,
    },
    folderKey: {
      type: String,
      default: null,
    },
    fromStorage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSaving: false,
    };
  },
  computed: {
    type() {
      switch (this.mediaType) {
        case MediaType.video:
          return 'video';
        case MediaType.photo:
          return 'image';
        case MediaType.audio:
          return 'audio';
        case MediaType.subtitles:
        case MediaType.document:
          return 'document';
        case MediaType.spreadsheet:
          return 'spreadsheet';
        case MediaType.presentation:
          return 'presentation';
        default: 
          console.log('mediaType not found in switch case so returning "file"', this.mediaType);
          return 'file';
      }
    },
    object() {
      if (this.mediaType === 'spreadsheet') {
        return 2;
      }
      if (this.mediaType === 'presentation') {
        return 1;
      }
      return 0;
    },
    getTitle() {
      return this.$t(`media.${this.editMedia ? 'edit' : 'upload'}-media`, [this.$tc(`media.name.${this.type}`, 1)]);
    }
  },
  created() {
    //console.log('MediaUploadModal created!!');
    const nameLangCodes = this.media && this.media.name ? Object.keys(this.media.name) : null;
    // const descLangCodes = this.media && this.media.description ? Object.keys(this.media.description) : null;

    if (nameLangCodes == null) return;
    if (typeof this.media.name === 'string') {
      return;
    }
    const newItemNames = {};
    const newItemDescription = {};

    for (const langCode of nameLangCodes) {
      if (isNaN(parseInt(langCode))) {
        newItemNames[langCode] = this.media?.name && this.media?.name[langCode] ? this.media?.name[langCode] : '-';
        newItemDescription[langCode] = this.media?.description && this.media?.description[langCode] ? this.media?.description[langCode] : '-';
      }
    }

    this.media.name = newItemNames;
    this.media.description = newItemDescription;
  },
  methods: {
    async postMedia(form, file, collaborative) {
      this.isSaving = true;
      try {
        if (file?.mediaFile == null && file?.thumbnail == null && file['mediaFile-1'] == null) {
          file = null;
        }

        if (!this.editMedia) {
          const item = {
            itemType: collaborative ? 'CreateCollaborativeDocuments' : 'media',
            customName: this.fromStorage ? 'storage' : 'media',
            storedKey: this.fromStorage ? null : this.mediaType,
            requestConfig: {
              name: form.name,
              description: form.description,
              canDownload: form.canDownload,
              isCompletable: form.isCompletable,
              isLink: form.isLink,
              linkURL: form.linkURL,
              mediaType: this.mediaType,
              objectType: this.object,
              folderKey: this.folderKey,
              multiFile: form.multiFile,
              includeThumbnail: form.includeThumbnail,
            },
          };
          // Create media item:
          const response = await this.$store.dispatch('createItem', {
            item: item,
            file,
          });
          this.$emit('media-created', true)
          this.notifySuccess(this.$t('success-message.general-success-create'));
        } else {
          const response = await this.$store.dispatch('editItem', {
            item: {
              itemType: 'media',
              customName: this.media.mediaType === 'file' ? 'storage' : 'media',
              storedKey: this.media.mediaType === 'file' ? null : this.mediaType,
              requestConfig: {
                key: this.media.key,
                mediaKey: this.media.key,
                name: form.name,
                description: form.description,
                canDownload: form.canDownload,
                isCompletable: form.isCompletable,
                isCollaborative: form.isCollaborative,
                isLink: form.isLink,
                linkURL: form.linkURL,
                creationDate: new Date(form.creationDate),
                mediaType: this.mediaType,
                objectType: this.object,
                folderKey: this.folderKey,
                includeThumbnail: form.includeThumbnail,
                deleteThumbnailURL: form.deleteThumbnailURL || false,
              },
            },
            file,
          });
          this.media = response.data.data[0];
          this.notifySuccess(this.$t('success-message.general-success-edit'));
        }
        if (this.datos) {
          await this.datos();
        }
        this.$emit('change', false);
        this.$emit('mediaCreated', true)
        this.isSaving = false;
        if (!this.editMedia) {
          return this.$emit('mediaCreated');
        } return;
      } catch {
        this.isSaving = false;
        this.notifyError(this.$t('error-message.general-error'));
      }
    },
  },
};
</script>
